<script setup lang="ts">
const { text, picto, label, altTitle, url, utm } = defineProps<{
  text: string;
  picto: string;
  altTitle: string;
  url: string;
  utm: string;
  label: string;
}>();

const { isDesktop } = useDevice();
</script>

<template>
  <div class="banner-infos-container classic">
    <CustomNuxtLink
      class="banner-infos-container-link"
      :to="`${url}?xtra_source=website&xtra_medium=cta&xtra_campaign=${utm}`"
    >
      <NuxtImg
        v-if="isDesktop"
        class="picto"
        :src="picto"
        :alt="altTitle"
        :title="altTitle"
      />

      <span class="banner-infos-text" v-html="text" />
      <ButtonLink
        :label="label"
        type="candidat small-banner candidat-white with-border"
        size="small"
      />
    </CustomNuxtLink>
  </div>
</template>

<style lang="scss"></style>
