<script setup lang="ts">
const route = useRoute();
const appConfig = useAppConfig();

const expirationDate = new Date(2025, 3, 28, 0, 0, 0);
const isAtTop = ref(true);

const shouldShowBanner = computed(() => {
  return (
    new Date() <= expirationDate &&
    !route.path.includes("/recrutement") &&
    !route.path.includes("/recruteurs") &&
    !route.path.includes("emploi-hotellerie") &&
    isAtTop.value
  );
});
function handleScroll() {
  isAtTop.value = window.scrollY === 0;
}

function setupScrollListener() {
  window.removeEventListener("scroll", handleScroll);
  window.addEventListener("scroll", handleScroll);
}

onMounted(() => {
  setupScrollListener();
});

watch(route, () => {
  setupScrollListener();
});
</script>

<template>
  <BannerInfos
    :class="shouldShowBanner ? 'visible' : 'hide-banner'"
    :url="appConfig.banner.url"
    :text="appConfig.banner.text"
    :label="appConfig.banner.cta"
    :utm="appConfig.banner.utm"
    :picto="appConfig.banner.picto"
    :alt-title="appConfig.banner.altTitle"
  />
</template>

<style lang="scss"></style>
